import { useContext, useEffect } from 'react';

import { getFullPathname } from '@rippling/utils/urlUtils';
import LogRocket from 'logrocket';
import { useRouter } from 'next/router';

import UserContext from '../context/UserContext';

const useLogRocket = () => {
  const { googleClientId } = useContext(UserContext);
  const router = useRouter();

  const fullPathname = getFullPathname({
    locale: router.locale ?? '',
    pathname: router.asPath,
  });

  useEffect(() => {
    if (!googleClientId) {
      return;
    }

    const shouldInitializeLogRocket = (): boolean => {
      const logRocketRoutes = [
        '/it-trial',
        '/request-demo',
        '/en-CA/request-demo',
      ];

      return (
        logRocketRoutes.some((route) => fullPathname.startsWith(route)) &&
        Boolean(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID)
      );
    };

    const initializeLogRocket = () => {
      try {
        if (process.env.NEXT_PUBLIC_LOGROCKET_APP_ID) {
          LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID);
          LogRocket.identify(googleClientId);
        }
      } catch (error) {
        console.error('Error initializing LogRocket:', error);
      }
    };

    if (shouldInitializeLogRocket()) {
      initializeLogRocket();
    }
  }, [fullPathname, googleClientId]);
};

export default useLogRocket;
